<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card">
        <form @submit.prevent="createIssue()">
          <div class="card-header">
            <h4>Create Issue</h4>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Title</label>
                  <input type="text" class="form-control" v-model="fields.title" placeholder="Any issues"/>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-right pt-0">
            <button class="btn btn-primary" :class="{ 'btn-progress': loading }">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import GeneralHelpers from "@/functions/actions/GeneralRequest";
export default {
  name: "createIssue",
  computed: {
    ...mapState(["loading"])
  },
  mounted() {},
  data() {
    return {
      fields: {
        title: ""
      },
    };
  },
  methods: {
    async createIssue() {
      if (this.validateData()) {
        const response = await GeneralHelpers.createIssue(this.fields);
        if (response.status) {
          this.showAlert("Success", "Issue has been created successfully", "success")
          this.$router.push({
            name: "All Issues"
          })
        } else {
          this.showAlert(
            "Error occured",
            `${response.message}. Kindly reload the page`,
            "error"
          );
        }
      }
    },
    validateData() {
      if (this.fields.title) {
        return true
      } else {
        this.showAlert(
          "Validation error",
          "Provide a title",
          "warning"
        );
      }
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style lang="">
</style>